import React, { useEffect, useState, useLayoutEffect } from 'react';
//import logo from './logo.svg';
import './App.css';
import  CircularProgress  from '@mui/joy/CircularProgress';
import  Alert from '@mui/joy/Alert';
import { BrowserRouter as Router, Routes, Route, Link, NavLink, useLocation, ScrollRestoration } from "react-router-dom";
import Recette from './recette';
import Home from './home';
import InfosContext from './infosContext';
import {Button, Row, Col, Toast} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
/*
npm install @mui/joy @emotion/react @emotion/styled
npm install --save firebase react-bootstrap bootstrap
*/



function App() {

  const olddata = localStorage.getItem("test") ? JSON.parse( localStorage.getItem("test") ) : [];
  //console.log(olddata);
  const [data, setData] = useState(olddata);
  const [statusfetch, setStatusfetch] = useState(0); // 0=nothing, 1=fetched, 2=error
  
  const [scrollValue, setScrollValue] = useState(0);
  const [selectedRecipy, setSelectedRecipy] = useState(-1);
  

  function getscroll() {
    return scrollValue;
  }
  useEffect(() =>
  {
    console.log("statusfetch = " + statusfetch);
    if (statusfetch === 0) {
      fetch("https://thermomix.picalix.com/api/all", {
        method: 'GET',
        mode: 'cors', //no-cors
        referrerPolicy: 'origin-when-cross-origin',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        }).then(response => {
          response.json().then(
            (json) => {
              console.log("LOADED FROM WEB:");
              console.log(json);
              localStorage.setItem("test", JSON.stringify( json ));
              setData(json);
              setStatusfetch(1);
            }
          );
          
      }).catch(() => {
        console.log("errror");
        setStatusfetch(2);
      })

  }//if
      
  }, [statusfetch]);

  function onRecipyChanged(id) {setSelectedRecipy(id);}
  function getSelectedRecipy() { return selectedRecipy;}

  return (
    <InfosContext.Provider value={data}>
    <Router>

    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}

        <table>
        <tbody>
          <tr>
            <td align="left" style={{width: '100%'}}><NavLink to="/">Home</NavLink></td>
            <td align="right" style={{whiteSpace: 'nowrap'}}>
              {statusfetch ===0 && 
              <CircularProgress color="primary" size="sm"/>
              }
              {statusfetch ===1 && 
              <Alert color='success' variant="solid">Synced</Alert>
              }
              {statusfetch ===2 && 
              <Alert color="danger" variant="solid">Error loading recipies !</Alert>
              }      
            </td>
          </tr>
          </tbody>
        </table>
      </header>
    </div>
      {/* https://dev.to/tywenk/how-to-use-nested-routes-in-react-router-6-4jhd */}
      <Routes>
        <Route path="/recette/:idrecette" element={<Recette />} />
        <Route path="/" element={<Home onRecipyChanged={onRecipyChanged} getSelectedRecipy={getSelectedRecipy} />} />
      </Routes>

    </Router>
    </InfosContext.Provider>
  );
}

export default App;
