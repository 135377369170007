import React, { useEffect, useState, useContext } from 'react';
//import logo from './logo.svg';
import './App.css';
import  CircularProgress  from '@mui/joy/CircularProgress';
import  Alert from '@mui/joy/Alert';
import { BrowserRouter as Router, useLocation, Routes, Route, Link, useNavigate } from "react-router-dom";

import Recette from './recette';
import InfosContext from './infosContext';
/*
npm install @mui/joy @emotion/react @emotion/styled
*/



function Home({onscroll,getscroll, getSelectedRecipy, onRecipyChanged}) {
    const navigate = useNavigate();
    const location = useLocation();

    const infos = useContext(InfosContext);
    const [filter, setFilter] = useState("");
    //const [scrollValue, setScrollValue] = useState(0);
    //location.hash = "#" + hash;

    useEffect(() => {
        //return () => window.removeEventListener('scroll', onScroll);
        //console.log("SCROLLINIT=" + getscroll() );
        //setTimeout( () => window.scrollTo(0, getscroll()), 110);

        //setTimeout( () => window.scrollTo({ top: getscroll(), left:0, behavior: "instant"}), 110 );

        console.log("RETURNING TO "+ "#rec" + getSelectedRecipy());
        if (location.pathname == "/dd")
        setTimeout( () => {
            //location.hash = "rec" + getSelectedRecipy();
            //const element = document.getElementById("rec" + getSelectedRecipy());
            //element.scrollIntoView({ behavior: "smooth"}); //smooth or instant
            //if (element) element.scrollIntoView();
        }, 1000);

      }, [location.pathname,infos]);
      
    useEffect(() => {
        //console.log("GOING TO " + scrollValue);
        //setTimeout( () => window.scrollTo(0, scrollValue), 0);
        return () => {
            console.log("UNMOUNT HOME");
        }
    }, []);

    useEffect(() => {
        const onScroll = (e) => {
            //console.log("GONE TO " + e.target.documentElement.scrollTop);
            //setScrollValue(e.target.documentElement.scrollTop);
            //onscroll( e.target.documentElement.scrollTop );
        };
        window.addEventListener('scroll', onScroll);

        return () => window.removeEventListener('scroll', onScroll);
    }, []);


    function handleOnClickRecette(id) {
        onRecipyChanged(id);
        navigate("/recette/" + id);
        
        //handleOnRecipySelected(id);
        //navigation.history.push("/recette/" + id);
        //navigation.push("/recette/" + id);
    }

    function LigneRecette({recette}) {
        //style={{opacity: recette.photos.length >0 ? 1:0.2}}
        let img = recette.photos.length >0 ? "https://thermomix.picalix.com/PHOTOS/mini/" + recette.photos[0].fichier + '.jpg' : 'https://thermomix.picalix.com/images/thxrecettes.png';
        //let img = 'https://thermomix.picalix.com/images/thxrecettes.png';

        let idtr = "rec" + recette.id;
        return (
                <tr className='ligne-recette' id={idtr} key={recette.id}  onClick={() => handleOnClickRecette( recette.id )}>
                    <td>
                        <div className="photo-une" style={{opacity: (recette.photos.length > 0 ? 1:0.2) }} >
                            <div className='photo-une-deux' style={{backgroundImage: `url(${img})`}}></div> 
                            </div>
                        </td>
                    <td>{recette.titre}</td>
                    <td>{recette.totalTemps} minutes</td>
                </tr>
        );
    }

    const listefiltre = !infos? [] : infos.filter((recette) =>
        //recette.titre.includes(filter)
        new RegExp(filter.normalize('NFD').replace(/\p{Diacritic}/gu, ''), 'i').test( recette.titre.normalize('NFD').replace(/\p{Diacritic}/gu, '') )
    );
    /*
  console.log(infos);
    const listItems = !infos? [] : infos.map((recette) =>
        <LigneRecette key={recette.id} recette={recette}></LigneRecette>
    );*/ 

    const listItems = listefiltre.map((recette) =>
        <LigneRecette key={recette.id} recette={recette}></LigneRecette>
    );


    function handleOnChangeFilter(e) {
        setFilter( e.target.value );
    }

  return (
    <>
        <label>Filtre&nbsp;:</label>  <input type='text' value={filter} onChange={handleOnChangeFilter}></input>
        
        <table className="table-recettes" border="0">
            <tbody>
                {listItems}
            </tbody>
        </table>
    </>
  );
}

export default Home;
