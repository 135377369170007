import React, {useContext, useEffect, useLayoutEffect} from "react";
import { BrowserRouter as Router, Routes, Route, Link, useParams, useLocation } from "react-router-dom";
import InfosContext from './infosContext';
import { Carousel, Modal } from "react-bootstrap";

//import About from "./About";
//import Home from "./Home";

function Recette() {
    let { idrecette } = useParams();
    const infos = useContext(InfosContext);
    let recette = infos.filter( rec => {
        return ( parseInt(rec.id) === parseInt(idrecette) );
    })[0];

    let img = recette.photos.length >0 ? "https://thermomix.picalix.com/PHOTOS/hd/" + recette.photos[0].fichier + '.jpg' : 'https://thermomix.picalix.com/images/thxrecettes.png';

    //console.log("recette");
    //console.log(recette);

//    let ingredients = recette.ingredients.replaceAll('\n', '<br/>');

    let ingredients = recette.ingredients.split('\n').map((item, idx) => {
        return (
          <span key={idx}>
            {item}
            <br/>
          </span>
        );
    });
    let preparation = recette.preparation.split('\n').map((item, idx) => {
        return (
          <span key={idx}>
            {item}
            <br/>
          </span>
        );
    });

    const { pathname } = useLocation();
    useEffect(() => {
      console.log("PATH=" + pathname);
      setTimeout( () => {
        //window.scrollTo(0, 0);
        window.scrollTo({ top:0, left:0, behavior: "instant"});
      }, 10);
      ;
    }, [pathname]);

    return (
        <>
        <div class="container">
        <h1>{recette.titre}</h1>
        
        <Carousel
        id="photos"
        controls={recette.photos.length > 1}
        indicators={recette.photos.length > 1}
      >
        {recette.photos.map((i, index) => {
          return (
            <Carousel.Item key={`img-${index}`}>
              <img
                src={"https://thermomix.picalix.com/PHOTOS/hd/" + recette.photos[index].fichier + '.jpg'}
                className="d-block w-100"
              />
              
              {/*<button className="zoom" onClick={() => handleShow(i)}></button>*/}
            </Carousel.Item>
          );
        })}
      </Carousel>

        


        <span className="rubrique">Ingrédients:</span><br/>
        <div className="recette-texte">
            {ingredients}
        </div>

        <br/>

        <span className="rubrique">Préparation:</span><br/>
        <div className="recette-texte">
            {preparation}
        </div>
        
        <br/>

        <span className="rubrique me-4">Temps:</span>{recette.totalTemps} minutes<br/>
        <span className="rubrique me-4">Personnes:</span>{recette.personnes} personnes<br/>
        </div>
        </>
    );
}

export default Recette;